import logoLink from '../resources/bayernwerk.svg';

export const cssVariables = {
    
    primary: '#0091BB',
    primary_light: '#A1D4EA',
    primary_dark: '#00749D',
    primary_transparent: '#E9F3F8',
    text_on_primary: 'white',
    
    secondary: '#E63121',
    secondary_light: '#ECB1AC',
    secondary_transparent: '#E0F4CE',
    text_on_secondary: 'white',
    
    font: 'Arial, Helvetica, sans-serif',
    link_color: '#0091BB',
    font_color: '#000',

    border_width: '1px',
    border_style: 'solid',

    active: '#0091BB',
    inactive: '#CCCCCC',

    edit_color: '#0091BB',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#E63121',

    dropdown_background: '#A1D4EA',
};

export const logo = logoLink;
